.files {
  display: flex;
  flex-wrap: wrap;

  .file {
    width: 150px;
    text-align: center;

    margin: 0 10px 10px;

    .fiv-viv {
      width: 100%;
      height: 100px;
    }
  }
}

#progress {
  position: fixed;
  top: 0;
  left: 0;
  width: 0;
  height: 5px;
  background: $primary;
  transition: width .5s;
  z-index: 100;
}

.form-group, #upload {
  margin-bottom: 1.5rem !important;
}

form {
  margin-bottom: 25px;
}


textarea {
  min-height: 100px;
}

label.required {
  &:before {
    content: "*";
    color: $main-pink;
  }
}
